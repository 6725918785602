import {AppStateService} from "@app/shared/services";
import {defaultEmptyPage} from "@app/shared/constants";
import {cloneObject} from "@app/shared/helpers/clone-object";
import {DocumentTypes, TemplateSectionTypes, TemplateSettingsShapes, TemplateTypes} from "@app/shared/enums";
import {
  AllTemplatesThemeColor,
  CoverLetter,
  Template,
  TemplateConfigs,
  TemplateSection,
  TemplateSettingsThemeColor
} from "@app/shared/interfaces";

export const extractColorNumber = (str: string) => {
  const regex = /color-(\d+)/; // Regular expression to match "color-" followed by digits
  const match = str.match(regex); // Find the match in the string

  if (match && match[1]) {
    return parseInt(match[1], 10); // Return the matched number as an integer
  }

  return null; // Return null if no match is found
}

export const isListSection = (section: TemplateSection) => {
  return [
    TemplateSectionTypes.SKILLS,
    TemplateSectionTypes.SOFT_SKILLS,
    TemplateSectionTypes.LANGUAGES,
    TemplateSectionTypes.INTERESTS,
    TemplateSectionTypes.CAUSES,
    TemplateSectionTypes.AREAS_OF_EXPERTISE,
  ]
    .includes(section.type)
}

export const isOperationIncludes = ($event: KeyboardEvent) => {
  return getOperations($event).some((op) => op);
}

export const getOperations = ($event: KeyboardEvent) => {
  const cutOperation = $event.ctrlKey && $event.key === 'x';
  const copyOperation = $event.ctrlKey && $event.key === 'c';
  const backOperation = $event.ctrlKey && $event.key === 'z';
  const selectAllOperation = $event.ctrlKey && $event.key === 'a';

  return [cutOperation, copyOperation, backOperation, selectAllOperation];
}

export const removeTags = (str: string) => {
  if ((str === null) || (str === '')) {
    return '';
  } else {
    str = str.toString();
  }
  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/ig, '');
}

export const getClipBoardTxt = ($event: ClipboardEvent) => {
  // @ts-ignore
  const clipboardData = $event.clipboardData || window.clipboardData;
  const pastedText = clipboardData.getData(' text/plain');

  return pastedText;
}

export const combineSectionsByType = (arr: TemplateSection[]): TemplateSection[] => {
  const resultMap = new Map();

  arr.forEach((item) => {
    const {type, ...rest} = item;

    if (!resultMap.has(type)) {
      resultMap.set(type, {type, ...rest, items: [...item.items]});
    } else {
      resultMap.get(type).items.push(...item.items);
    }
  });

  return Array.from(resultMap.values());
}


/** Get Template Sections Logic Start */

export const getOneRowAllPagesSections = (template: TemplateConfigs) => {
  return template.pages.items.map((page) => page.section.items.map((section) => section)).flat();
}

export const getTwoRowAllPagesSections = (template: TemplateConfigs) => {
  return template.pages.items
    .map((page) => [
      ...page.twoRowLeftSideSection.items.map((section) => section),
      ...page.twoRowRightSideSection.items.map((section) => section),
    ])
    .flat();
}

export const getSidebarAllPagesSections = (template: TemplateConfigs) => {
  return template.pages.items
    .map((page) => [
      ...page.sidebarSection.items.map((section) => section),
      ...page.sidebarRightSection.items.map((section) => section),
    ])
    .flat();
}

export const getOneRowAllPagesListSections = (template: TemplateConfigs) => {
  return template.pages.items
    .map((page) => page.section.items.map((section) => section))
    .flat()
    .filter((section) => isListSection(section));
}

export const getTwoRowAllPagesListSections = (template: TemplateConfigs) => {
  return template.pages.items
    .map((page) => [
      ...page.twoRowLeftSideSection.items.map((section) => section),
      ...page.twoRowRightSideSection.items.map((section) => section),
    ])
    .flat()
    .filter((section) => isListSection(section));
}

export const getSidebarAllPagesListSections = (template: TemplateConfigs) => {
  return template.pages.items
    .map((page) => [
      ...page.sidebarSection.items.map((section) => section),
      ...page.sidebarRightSection.items.map((section) => section),
    ])
    .flat()
    .filter((section) => isListSection(section));
}

export const filterSectionsByTypes = (sections: TemplateSection[], types: TemplateSectionTypes[] = []) => {
  return sections.filter((section) => types.includes(section.type));
}

export const getOneRowSectionsByType = (template: TemplateConfigs, section: TemplateSection) => {
  return template.pages.items
    .map((page) => page.section.items.filter((item) => item.type === section.type))
    .flat();
}

export const getTwoRowsSectionsByType = (template: TemplateConfigs, section: TemplateSection) => {
  return template.pages.items
    .map((page) => {
      return [
        ...page.twoRowLeftSideSection.items.filter((item) => item.type === section.type),
        ...page.twoRowRightSideSection.items.filter((item) => item.type === section.type),
      ]
    })
    .flat();
}

export const getSidebarSectionsByType = (template: TemplateConfigs, section: TemplateSection) => {
  return template.pages.items
    .map((page) => {
      return [
        ...page.sidebarSection.items.filter((item) => item.type === section.type),
        ...page.sidebarRightSection.items.filter((item) => item.type === section.type),
      ]
    })
    .flat();
}

/* Totals */

export const get_sidebar_tm_total_listSections = (template: TemplateConfigs) => {
  const listSections = [
    ...getSidebarAllPagesListSections(template),
    ...template.allSections.items.filter((section) => isListSection(section))
  ];

  return listSections;
}

export const get_allTypes_tm_total_listSections = (template: TemplateConfigs) => {
  const listSections = [
    ...getOneRowAllPagesListSections(template),
    ...getTwoRowAllPagesListSections(template),
    ...getSidebarAllPagesListSections(template),
    ...template.allSections.items.filter((section) => isListSection(section))
  ];

  return listSections;
}

export const get_allTypes_tm_total_all_sections = (template: TemplateConfigs) => {
  const listSections = [
    ...getOneRowAllPagesSections(template),
    ...getTwoRowAllPagesSections(template),
    ...getSidebarAllPagesSections(template),
    ...template.allSections.items
  ];

  return listSections;
}

/** Get Template Sections Logic End */

/** Does Section Exist On Template Logic Start */

export const isSidebarTmHasSection = (template: TemplateConfigs, type: TemplateSectionTypes) => {
  const sections = getSidebarAllPagesSections(template);
  return sections.some((section) => section.type === type);
}

export const findSidebarTmTotalSection = (template: TemplateConfigs, type: TemplateSectionTypes) => {
  const sections = get_sidebar_tm_total_listSections(template);
  return sections.find((section) => section.type === type);
}

export const findTmTotalSectionByType = (template: TemplateConfigs, type: TemplateSectionTypes) => {
  const sections = get_allTypes_tm_total_all_sections(template);
  return sections.find((section) => section.type === type);
}

/** Does Section Exist On Template Logic End */


export const onChangeExistingTemplate = (current_template: Template, template: Template) => {

  if (template.documentType === DocumentTypes.CV) {
    const selectedTemplate = cloneObject(template) as Template<TemplateConfigs>;
    const currentTemplate = current_template as Template<TemplateConfigs>;

    currentTemplate.templateId = selectedTemplate.templateId;
    currentTemplate.settings.pureTemplate = selectedTemplate.settings.pureTemplate;
    currentTemplate.premium = selectedTemplate.premium;

    currentTemplate.settings.premium = selectedTemplate.settings.premium;
    currentTemplate.settings.templateTopPartType = selectedTemplate.settings.templateTopPartType;

    // cvTemplate.settings.img.state = selectedTemplate.settings.img.state;
    currentTemplate.settings.img.settings.selectedShape.value = selectedTemplate.settings.img.settings.selectedShape.value;
    currentTemplate.settings.summary.icon = selectedTemplate.settings.summary.icon;
    // cvTemplate.settings.summary.title.state = selectedTemplate.settings.summary.title.state;

    currentTemplate.settings.settings.theme.colorId = selectedTemplate.settings.settings.theme.colorId;

    currentTemplate.settings.socialInfo.title.state = selectedTemplate.settings.socialInfo.title.state;
    currentTemplate.settings.socialInfo.onSidebar = selectedTemplate.settings.socialInfo.onSidebar;

    currentTemplate.settings.settings.font = selectedTemplate.settings.settings.font;

    const allCurrentTmSections = [
      ...getOneRowAllPagesSections(currentTemplate.settings),
      ...getTwoRowAllPagesSections(currentTemplate.settings),
      ...getSidebarAllPagesSections(currentTemplate.settings),
      ...currentTemplate.settings.allSections.items
    ];

    const allSelectedTmSections = [
      ...getOneRowAllPagesSections(selectedTemplate.settings),
      ...getTwoRowAllPagesSections(selectedTemplate.settings),
      ...getSidebarAllPagesSections(selectedTemplate.settings),
      ...selectedTemplate.settings.allSections.items
    ];

    allCurrentTmSections.forEach((s1) => {
      allSelectedTmSections.forEach((s2) => {
        if (s1.type === s2.type) {
          s1.title.icon = s2.title.icon;
        }
      });
    });

    if (template.settings.pureTemplate) {

      currentTemplate.settings.summary.title.state = true;
      currentTemplate.settings.socialInfo.title.state = true;
      currentTemplate.settings.socialInfo.onSidebar = true;
      currentTemplate.settings.img.state = false;


      currentTemplate.settings.socialInfo.items
        .filter((item) => item.settings?.shorten)
        .forEach((item) => item.settings!.shorten = false);

      const allListSections = get_allTypes_tm_total_listSections(currentTemplate.settings as TemplateConfigs);

      allListSections.forEach((section) => {
        section.hasIcon = false;

        if (section.infographics?.state) {
          section.infographics.state = false;
        }

        if (section.sectionInfo?.icon) {
          section.sectionInfo.icon = '';
        }

        const shape = section.settings?.selectedShape;
        if (shape) {
          shape.chipValue = '';
          shape.hasIcon = false;
          shape.infographicValue = '';
          if (shape.type !== TemplateSettingsShapes.TEXT_OPTIONS) {
            shape.type = TemplateSettingsShapes.TEXT;
          }
        }

      });
    }

  }

  if (template.documentType === DocumentTypes.COVER) {
    const selectedTemplate = cloneObject(template) as Template<CoverLetter>;
    const currentCoverLetter = current_template as Template<CoverLetter>;

    currentCoverLetter.templateId = selectedTemplate.templateId;
    currentCoverLetter.settings.pureTemplate = selectedTemplate.settings.pureTemplate;
    currentCoverLetter.premium = selectedTemplate.premium;

    currentCoverLetter.settings.img.settings.selectedShape.value = selectedTemplate.settings.img.settings.selectedShape.value;

    currentCoverLetter.settings.templateId = selectedTemplate.settings.templateId;
    currentCoverLetter.settings.templateTopPartType = selectedTemplate.settings.templateTopPartType;

    currentCoverLetter.settings.settings.theme.colorId = selectedTemplate.settings.settings.theme.colorId;


    if (currentCoverLetter.settings.pureTemplate) {
      currentCoverLetter.settings.img.state = false;

      currentCoverLetter.settings.socialInfo.items
        .filter((item) => item.settings?.shorten)
        .forEach((item) => item.settings!.shorten = false);
    }
  }

}

export const getTmSectionsByType = (template: TemplateConfigs, type: string) => {
  let allSections: TemplateSection[] = [];

  switch (template.type) {
    case TemplateTypes.ONE_ROW: {
      allSections = getOneRowAllPagesSections(template);
      break;
    }
    case TemplateTypes.SIDEBAR: {
      allSections = getSidebarAllPagesSections(template);
      break;
    }
    case TemplateTypes.TWO_ROWS: {
      allSections = getTwoRowAllPagesSections(template);
      break;
    }
  }

  const result = allSections.filter((section) => section.type === type);

  return result;
}


/** Template Paid Logic Start */

export const isTmHasPaidFeature = (tm: Template, appStateService: AppStateService) => {
  let state = false;

  switch (tm.documentType) {
    case DocumentTypes.CV: {
      const template = tm.settings as TemplateConfigs;

      if (template.pages.items.length > 1) {
        state = true;
      }

      if (template.premium) {
        state = true;
      }

      if (template.img.settings.selectedShape.value !== 'initial') {
        state = true;
      }

      const paidFonts = appStateService.templateFonts.filter((font) => font.paid)
        .map((font) => font.fontFamily);

      if (paidFonts.includes(template.settings.font.type.fontFamily)) {
        state = true;
      }

      if (!template.branding.enabled) {
        state = true;
      }

      const templateId = tm.templateId || 1;
      const paidColorsIds = Object.values(appStateService.templateThemeColor[('template' + templateId) as keyof AllTemplatesThemeColor])
        .filter((color: TemplateSettingsThemeColor) => color.paid)
        .map((color: TemplateSettingsThemeColor) => color.id);

      if (paidColorsIds.includes(template.settings.theme.colorId)) {
        state = true;
      }

      if (isPaidInfExist(template)) {
        state = true;
      }

      break;
    }
    case DocumentTypes.COVER: {
      const template = tm.settings as CoverLetter;

      state = true;

      if (!template.branding.enabled) {
        state = true;
      }

      break;
    }
  }

  return state;
}

export const isPaidInfExist = (template: TemplateConfigs) => {
  let state = false;

  let listSections: TemplateSection[] = [];

  switch (template.type) {
    case TemplateTypes.SIDEBAR: {
      listSections = getSidebarAllPagesListSections(template)
      break
    }
    case TemplateTypes.ONE_ROW: {
      listSections = getOneRowAllPagesListSections(template)
      break;
    }
    case TemplateTypes.TWO_ROWS: {
      listSections = getTwoRowAllPagesListSections(template)
      break
    }
  }

  const paidInfSecExist = listSections
    ?.filter((section) => section.settings?.selectedShape.infographicValue) // infographic-round is the only free infographic
    ?.some((section) => section.settings?.selectedShape.infographicValue !== 'infographic-round');

  if (paidInfSecExist) {
    state = true;
  }

  return state;
}

/** Template Paid Logic End */


/** Change Template Layout Start */

export const onChangeTemplateType = (template: TemplateConfigs, type: TemplateTypes, splitToPagesAfterTypeChange: (sections: TemplateSection[]) => void) => {

  if (template.type === type) {
    return;
  }

  switch (type) {
    case TemplateTypes.ONE_ROW: {

      let allSections: TemplateSection[] = [];

      switch (template.type) {
        case TemplateTypes.SIDEBAR: { // Collect from sidebar and give to one-row
          allSections = [
            template.pages.items.map((page) => page.sidebarSection.items.map((section) => section)).flat(),
            template.pages.items.map((page) => page.sidebarRightSection.items.map((section) => section)).flat(),
          ].flat();
          break;
        }
        case TemplateTypes.TWO_ROWS: { // Collect from two-rows and give to one-row
          allSections = [
            template.pages.items.map((page) => page.twoRowLeftSideSection.items.map((section) => section)).flat(),
            template.pages.items.map((page) => page.twoRowRightSideSection.items.map((section) => section)).flat(),
          ].flat();
          break;
        }
      }

      const allCombinedSections = combineSectionsByType(allSections);
      const listSections = allCombinedSections.filter((section) => isListSection(section));
      const noTListSections = allCombinedSections.filter((section) => !isListSection(section));

      template.pages.items = [cloneObject(defaultEmptyPage)];
      template.pages.items[0].section.items = [...noTListSections, ...listSections];

      splitToPagesAfterTypeChange(allCombinedSections);
      break;
    }
    case TemplateTypes.SIDEBAR: {

      let allSections: TemplateSection[] = [];

      switch (template.type) {
        case TemplateTypes.ONE_ROW: { // collect from one-row and give to sidebar
          allSections = template.pages.items.map((page) => page.section.items.map((section) => section)).flat();
          break;
        }
        case TemplateTypes.TWO_ROWS: { // collect from two-row and give to sidebar
          allSections = [
            template.pages.items.map((page) => page.twoRowLeftSideSection.items.map((section) => section)).flat(),
            template.pages.items.map((page) => page.twoRowRightSideSection.items.map((section) => section)).flat(),
          ].flat();
          break;
        }
      }

      const allCombinedSections = combineSectionsByType(allSections);

      const listSections = allCombinedSections.filter((section) => isListSection(section));
      const noTListSections = allCombinedSections.filter((section) => !isListSection(section));

      template.pages.items = [cloneObject(defaultEmptyPage)];

      template.pages.items[0].sidebarSection.items = listSections;
      template.pages.items[0].sidebarRightSection.items = noTListSections;

      splitToPagesAfterTypeChange(allCombinedSections);
      break;
    }
    case TemplateTypes.TWO_ROWS: {
      let allSections: TemplateSection[] = [];

      switch (template.type) {
        case TemplateTypes.ONE_ROW: { // collect from one-row and give to two-row
          allSections = template.pages.items.map((page) => page.section.items.map((section) => section)).flat();
          break;
        }
        case TemplateTypes.SIDEBAR: { // collect from sidebar and give to two-row
          allSections = [
            template.pages.items.map((page) => page.sidebarSection.items.map((section) => section)).flat(),
            template.pages.items.map((page) => page.sidebarRightSection.items.map((section) => section)).flat(),
          ].flat();
          break;
        }
      }

      const allCombinedSections = combineSectionsByType(allSections);
      const oddSections = allCombinedSections.filter((_, index) => index % 2 !== 0);
      const evenSections = allCombinedSections.filter((_, index) => index % 2 === 0);

      template.pages.items = [cloneObject(defaultEmptyPage)];
      template.pages.items[0].twoRowLeftSideSection.items = oddSections;
      template.pages.items[0].twoRowRightSideSection.items = evenSections;

      splitToPagesAfterTypeChange(allCombinedSections);
      break;
    }
  }

  template.type = type;
}

/** Change Template Layout End */

