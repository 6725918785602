import {Router} from "@angular/router";
import {Template} from "@app/shared/interfaces";
import {DocumentTypes} from "@app/shared/enums";
import {NgOptimizedImage} from '@angular/common';
import {NzImageModule} from "ng-zorro-antd/image";
import {NzSpinComponent} from "ng-zorro-antd/spin";
import {NzUploadComponent} from "ng-zorro-antd/upload";
import {NzDividerComponent} from "ng-zorro-antd/divider";
import {NzTooltipDirective} from "ng-zorro-antd/tooltip";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {cloneObject, extractColorNumber} from "@app/shared/helpers";
import {TmButtonsComponent, TmPreviewComponent} from "@app/shared/ui-kits";
import {ChangeDetectionStrategy, Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {AnalyticsService, AppStateService, AuthService, TemplateService} from "@app/shared/services";

@Component({
  selector: 'sf-templates-section',
  templateUrl: './templates-section.component.html',
  styleUrls: ['./templates-section.component.scss'],
  imports: [
    NzImageModule,
    NgOptimizedImage,
    NzDividerComponent,
    NzTooltipDirective,
    TmPreviewComponent,
    NzUploadComponent,
    NzSpinComponent,
    TmButtonsComponent
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplatesSectionComponent implements OnInit {
  private readonly router = inject(Router);
  private readonly destroyRef = inject(DestroyRef);
  private readonly authService = inject(AuthService);
  private readonly appStateService = inject(AppStateService);
  private readonly templateService = inject(TemplateService);
  private readonly analyticsService = inject(AnalyticsService);

  protected readonly DocumentTypes = DocumentTypes;

  @Input() ngTitle = 'Resume'
  @Input({required: true}) templates: Template[] = [];

  constructor() {
  }

  ngOnInit() {
  }

  protected onPreviewTemplate($event: Event, template: Template) {
    $event.stopPropagation();
    this.appStateService.templatePreview$.next({$event, template});
    this.analyticsService.track("Template Previewed / cv-templates", {templateId: template.templateId});
  }

  protected onSelectTemplate(template: Template) {
    if (this.authService.isAuthenticated) {
      const templateCopy = cloneObject(template);
      templateCopy.settings.settings.theme.colorId = this.appStateService.getTmColors(template)[(extractColorNumber(template.src)! - 1) || 0].id;

      this.templateService.addTemplate(templateCopy)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((res) => {
          this.router.navigate(['documents', res.documentId]);
        });

      this.analyticsService.track("Template Choose / cv-templates", {
        templateId: templateCopy.templateId,
        isPremiumTemplate: templateCopy.premium
      });

    } else {
      this.router.navigate(['auth'], {
        queryParams: {
          returnUrl: this.ngTitle === 'CV' ? 'cv-templates' : 'cover-letters'
        }
      });
    }
  }

}

