<div class="home-page-main-container">

  <div class="img-vector-CV-wrapper">
    <section class="main-section">
      <div class="lef-side">
        <h1>The Online Resume Builder for Job-Ready Resumes in Any Career.</h1>

        <h2 class="mb-30 fs-16 l-height-initial">
          Pick a resume template and build your resume in as little as 5 minutes for free.
        </h2>

        <a class="sf-btn-primary" routerLink="/dashboard">Build My Resume</a>
      </div>

      <div class="right-side"></div>
    </section>

    <sf-why-use-us-section/>
  </div>

  <div class="S-logo-sections">
    <sf-resume-examples-section/>

    <div class="find-new-career-section">
      <section>
        <div class="small-desc">selfcv</div>

        <h2>Find new career opportunities with the help of selfcv</h2>

        <div class="mb-52">Start Building Your Resume For Free!</div>

        <a class="sf-btn-primary w-250" routerLink="/cv-templates">Browse Resume Templates</a>
      </section>
    </div>

    <div class="build-your-resume-section">
      <section>
        <div class="top-part">
          <div class="blue-small-desc">Find the best solutions for you</div>

          <h2>Build your resume in <b>4 simple steps</b></h2>

          <p>
            There are no additional services involved. Just follow the simple process described below to create and
            download your resume.
          </p>
        </div>

        <div class="body-part">
          <div class="step-item" *ngFor="let step of resumeBuildSteps">
            <i class="{{step.icon}}"></i>
            <div>{{ step.title }}</div>
            <p>{{ step.desc }}</p>
          </div>

          <i class="sf-icon-icon-logo"></i>
        </div>

      </section>
    </div>

  </div>

  <sf-faq-section [faq]="appStateService.FAQObj.homePage"/>
  <sf-more-then-section/>
</div>
