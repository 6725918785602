import {environment} from "@env";
import {finalize} from "rxjs/operators";
import {RouterLink} from "@angular/router";
import {ToFixedPipe} from "@app/shared/pipes";
import {copyToClipboard} from "@app/shared/helpers";
import {SfTrimDirective} from "@app/shared/directives";
import {NzPopoverDirective} from "ng-zorro-antd/popover";
import {NzTooltipDirective} from "ng-zorro-antd/tooltip";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {AmeriaBankInit, AmeriaBankInitRes} from "@app/shared/interfaces";
import {DecimalPipe, NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {DropdownComponent} from "@app/shared/ui-kits/dropdown/dropdown.component";
import {AnalyticsService, AppStateService, PaymentService} from "@app/shared/services";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {CountryPrice, PADDLE_SUPPORTED_COUNTRIES, PADDLE_ZIP_CODE_COUNTRIES} from "@app/shared/constants";
import {
  NzAutocompleteComponent,
  NzAutocompleteOptionComponent,
  NzAutocompleteTriggerDirective
} from "ng-zorro-antd/auto-complete";

// declare const Paddle: any;

@Component({
  selector: 'sf-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  imports: [
    NgForOf,
    RouterLink,
    NgIf,
    NgOptimizedImage,
    DecimalPipe,
    ToFixedPipe,
    ReactiveFormsModule,
    SfTrimDirective,
    NzAutocompleteComponent,
    NzAutocompleteOptionComponent,
    NzAutocompleteTriggerDirective,
    DropdownComponent,
    NzPopoverDirective,
    NzTooltipDirective,
  ],
  standalone: true
})
export class PaymentComponent implements OnInit {
  public readonly destroyRef = inject(DestroyRef);
  public readonly paymentService = inject(PaymentService);
  public readonly appStateService = inject(AppStateService);
  private readonly analyticsService = inject(AnalyticsService);

  public goToDashboardBtnState = false;

  protected currentCountry = PADDLE_SUPPORTED_COUNTRIES.items
    .find((item) => item.code === this.appStateService.userLocation?.country);

  protected readonly formGroup = new FormGroup({
    userName: new FormControl({disabled: true, value: this.appStateService.user?.fullName}),
    email: new FormControl({disabled: true, value: this.appStateService.user?.email}),
    country: new FormControl(this.currentCountry?.name, [Validators.required]),
    zipCode: new FormControl(null, PADDLE_ZIP_CODE_COUNTRIES.includes(this.appStateService.pricePreview?.data.address.countryCode || '') ? [Validators.required, /*Validators.pattern(/^\d{5}(-\d{4})?$/)*/] : [])
  });

  protected readonly PADDLE_ZIP_CODE_COUNTRIES = PADDLE_ZIP_CODE_COUNTRIES;
  protected filteredCountries = PADDLE_SUPPORTED_COUNTRIES.items;

  // protected couponInputState = false;
  protected coupon = ''; //cAPO7123
  protected couponBtnLoadingState = false;

  protected discount_code = ''; // 3BS1BTK6EN

  // protected paddleInitState = false;

  protected nextBtnLoadingState = false;

  @Input({required: true}) billing!: CountryPrice;
  @Output() close$ = new EventEmitter<void>();

  ngOnInit() {
    // this.initPaddle();

    this.appStateService.checkoutComplete$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.goToDashboardBtnState = true;
      });
  }

  /*private initPaddle() {
    const singleProduct = pricePreviewBodyItems
      .find((item) => item.priceId === this.billing.price.id);

    console.log('singleProduct', singleProduct);
    console.log('email', this.appStateService.user?.email);
    console.log('countryCode', this.appStateService.pricePreview?.data.address.countryCode);

    Paddle.Checkout.open(this.paddleSettings);
    Paddle.Spinner.show();
  }*/

  /*private get paddleSettings() {
    const singleProduct = pricePreviewBodyItems
      .find((item) => item.priceId === this.billing.price.id);

    console.log('this.coupon', this.coupon);

    return {
      items: [singleProduct],
      settings: {
        allowedPaymentMethods: ["apple_pay", "card", "google_pay", "paypal"],
        displayMode: 'inline',
        frameTarget: 'checkout-container', // className of your checkout <div>
        frameInitialHeight: 450, // `450` or above
        allowLogout: false, // disabling email field change
        showAddDiscounts: false, // disabling adding discount this way
        locale: this.appStateService.selectedLanguage.key,
        frameStyle: 'width:100%; min-width: 312px; background-color: transparent; border: none;' // `min-width` must be set to `286px` or above with checkout padding off; `312px` with checkout padding on.
      },
      customData: {
        email: this.appStateService.user?.email,
        coupon: this.coupon, // this need to be removed dynamically if PaddleEventTypes.CHECKOUT_COUPON_REMOVE event occurred - second step of paddle form (small recycle bin)
      },
      customer: {
        email: this.appStateService.user?.email,
        address: {
          countryCode: this.appStateService.pricePreview?.data.address.countryCode
        }
      },
      discountCode: this.discount_code // if one exists - applied successfully in step 1 form,
      // this need to be removed dynamically if PaddleEventTypes.CHECKOUT_COUPON_REMOVE event occurred - second step of paddle form (small recycle bin)
    }
  }*/

  protected onCLose() {
    this.close$.emit()
  }

  protected onSubmit() {
    console.log(this.formGroup);
    if (this.formGroup.valid) {
      this.ameriaPaymentInit();
      // this.paddleInitState = true;

      // setTimeout(() => this.initPaddle(), 300);

    }
  }

  private ameriaPaymentInit() {
    this.nextBtnLoadingState = true;

    let description = `${this.billing.description} plan was selected.`;

    if (this.appStateService.userLocation?.country !== 'AM') {
      description = 'Your currency is converted into AMD. You will not be charged any additional fees.';
    }

    const body: AmeriaBankInit = {
      planId: this.billing.id,
      currency: this.billing.currency,
      backUrl: window.location.href,
      description,
      email: this.appStateService.user?.email || ''
    };

    this.analyticsService.track("To Ameria Payment", body);
    this.paymentService.ameriaPaymentInit(body)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        {
          next: (res: AmeriaBankInitRes) => {
            const url = `${environment.ameriaVposBaseUrl}/VPOS/Payments/Pay?id=${res.PaymentID}&lang=en`;
            window.location.href = url;
          },
          error: () => this.nextBtnLoadingState = false
        }
      );
  }

  protected applyCoupon(inputEl: HTMLInputElement) {
    this.couponBtnLoadingState = true;
    this.paymentService.applyCoupon({coupon: inputEl.value})
      .pipe(
        finalize(() => this.couponBtnLoadingState = false),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((res) => {
        console.log(res);
        this.coupon = inputEl.value;
        this.discount_code = res.discount_code;
      });
  }

  protected copyDiscountIntoClipboard() {
    copyToClipboard(this.discount_code);
  }

}
