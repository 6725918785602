import {NzIconDirective} from "ng-zorro-antd/icon";
import {NzModalService} from "ng-zorro-antd/modal";
import {AppStateService} from "@app/shared/services";
import {NzTooltipDirective} from "ng-zorro-antd/tooltip";
import {NzPopoverDirective} from "ng-zorro-antd/popover";
import {PremiumPlansComponent} from "@app/shared/ui-kits";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {NzDrawerRef, NzDrawerService} from "ng-zorro-antd/drawer";
import {DocumentTypes, PaidFeatures, TemplateTypes} from "@app/shared/enums";
import {FilteredPaidFeaturesPipe} from "@app/shared/pipes/template-pipes/filtered-paid-features.pipe";
import {Component, DestroyRef, ElementRef, inject, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {
  cloneObject,
  getOneRowAllPagesListSections,
  getOneRowAllPagesSections,
  getSidebarAllPagesListSections,
  getSidebarAllPagesSections,
  getTwoRowAllPagesListSections,
  getTwoRowAllPagesSections,
  isPaidInfExist
} from "@app/shared/helpers";
import {
  AllCoverLettersThemeColor,
  AllTemplatesThemeColor,
  CoverLetter,
  PaidFeature,
  Template,
  TemplateConfigs,
  TemplateSection,
  TemplateSettingsThemeColor,
  TmFont
} from "@app/shared/interfaces";


@Component({
  selector: 'sf-upgrade-banner',
  templateUrl: './upgrade-banner.component.html',
  styleUrl: './upgrade-banner.component.scss',
  imports: [
    NzTooltipDirective,
    NzIconDirective,
    NzPopoverDirective,
    FilteredPaidFeaturesPipe,
  ],
  standalone: true,
})
export class UpgradeBannerComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  private readonly nzModalService = inject(NzModalService);
  protected readonly appStateService = inject(AppStateService);
  private readonly nzDrawerService = inject(NzDrawerService);

  @Input({required: true}) public template!: Template;

  @ViewChild('SelectPaidFeaturesRef', {static: true}) private selectPaidFeatures!: TemplateRef<{
    $implicit: { value: string };
    drawerRef: NzDrawerRef<string>;
  }>;

  @ViewChild('FeaturesDrawerTitleRef', {static: true}) private featuresDrawerTitleRef!: TemplateRef<ElementRef>;
  @ViewChild('PlansDrawerTitleRef', {static: true}) private plansDrawerTitleRef!: TemplateRef<ElementRef>;

  private nzDrawerRef: NzDrawerRef | null = null;

  protected countryPlan = this.appStateService.getCurrentCountyPlan()!;

  protected selectedPaidFeatures: PaidFeature[] = [
    {
      title: 'Premium Template',
      state: false,
      revertible: true,
      key: PaidFeatures.PREMIUM_TEMPLATE,
      action: () => {
        this.nzModalService.info({
          nzTitle: 'Replace Template',
          nzContent: 'By continue your template will be replaced with non Premium one.',
          nzOnOk: () => {
            this.onChangeExistingTemplate(this.appStateService.templates[0]);
            this.saveChanges();
          },
          nzOkText: 'Continue'
        });
      }
    },
    {
      title: 'Photo Style',
      state: false,
      revertible: true,
      key: PaidFeatures.PHOTO_STYLE,
      action: () => {
        this.template.settings.img.settings.selectedShape.value = 'initial';
        this.disableFeature(PaidFeatures.PHOTO_STYLE);
        this.saveChanges();
      }
    },
    {
      title: 'Font Family',
      state: false,
      revertible: true,
      key: PaidFeatures.FONT,
      action: () => {
        this.disableFeature(PaidFeatures.FONT);
        this.template.settings.settings.font.type = <TmFont>this.appStateService.templateFonts
          .find((item) => item.fontFamily === 'Poppins');
        this.saveChanges();
      }
    },
    {
      title: 'Theme Color',
      state: false,
      revertible: true,
      key: PaidFeatures.THEME_COLOR,
      action: () => {
        const templateId = this.template.templateId || 1;
        switch (this.template.documentType) {
          case DocumentTypes.CV: {
            (this.template.settings as TemplateConfigs).settings.theme.colorId =
              this.appStateService.templateThemeColor[('template' + templateId) as keyof AllTemplatesThemeColor].theme1.id;
            break;
          }
          case DocumentTypes.COVER: {
            (this.template.settings as CoverLetter).settings.theme.colorId =
              this.appStateService.coverLettersThemeColor[('template' + templateId) as keyof AllCoverLettersThemeColor].theme1.id;
            break;
          }
        }
        this.disableFeature(PaidFeatures.THEME_COLOR);
        this.appStateService.templateThemeChanged$.next();
        this.saveChanges();
      }
    },
    {
      title: 'Infographics',
      state: false,
      revertible: true,
      key: PaidFeatures.INFOGRAPHICS,
      action: () => {
        this.disableFeature(PaidFeatures.INFOGRAPHICS);
        this.unsetInfographicsFeature(this.template.settings as TemplateConfigs);
        this.saveChanges();
      }
    },
    {
      title: 'Creative Background',
      state: false,
      revertible: true,
      key: PaidFeatures.CREATIVE_BACKGROUND,
      action: () => {
      }
    },
    {
      title: 'Replaced Top Part',
      state: false,
      revertible: true,
      key: PaidFeatures.REPLACED_TOP_PART,
      action: () => {
      }
    },
    {
      title: 'Branding',
      state: false,
      revertible: true,
      key: PaidFeatures.BRANDING,
      action: () => {
        this.disableFeature(PaidFeatures.BRANDING);
        this.template.settings.branding.enabled = true;
        this.saveChanges();
      }
    },
    {
      title: 'More then One page',
      state: false,
      revertible: false,
      key: PaidFeatures.MORE_THEN_ONE_PAGE,
      infoMessage: 'CV Templates more than one page are enabled for premium accounts.'
    },
    {
      title: 'Cover Letter',
      state: false,
      revertible: false,
      key: PaidFeatures.COVER_LETTER,
      infoMessage: 'All cover letters are enabled for premium accounts.'
    },
  ];

  ngOnInit() {
    this.appStateService.openUpgradeBanner$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.onSeeMore();
      });

    this.appStateService.userLocationInit$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.countryPlan = this.appStateService.getCurrentCountyPlan()!;
      });
  }

  private saveChanges() {
    this.appStateService.saveChanges$.next();
  }

  private enableFeature(keyName: PaidFeatures) {
    const feature = this.selectedPaidFeatures.find((item) => item.key === keyName);
    if (feature) {
      feature.state = true;
    }
  }

  private disableFeature(keyName: PaidFeatures) {
    const feature = this.selectedPaidFeatures.find((item) => item.key === keyName);
    if (feature) {
      feature.state = false;
    }
    this.closeFeaturesDrawer();
  }

  protected onUpgrade() {
    if (!this.appStateService.user?.emailVerified) {
      this.nzModalService.info({
        nzTitle: 'First you need to verify your email.',
        // nzContent: '<p>Please check your inbox for a verification email from us. If you didn’t receive an email, check your spam or junk folder, or click the button below to resend the verification email.</p>',
      });

      return;
    }

    this.nzDrawerRef?.close();
    this.nzDrawerRef = this.nzDrawerService.create({
      nzContent: PremiumPlansComponent,
      nzClosable: false,
      nzWidth: 400,
      nzTitle: this.plansDrawerTitleRef,
      nzContentParams: {
        parentClassname: 'inside-nz-drawer'
      }
    });
  }

  protected onSeeMore() {
    this.nzDrawerRef = this.nzDrawerService.create({
      nzContent: this.selectPaidFeatures,
      nzClosable: false,
      nzTitle: this.featuresDrawerTitleRef,
      nzWrapClassName: 'paid-banner-drawer-title',
      nzPlacement: 'bottom',
      nzHeight: 'auto',
    });

    Object.values(this.selectedPaidFeatures).forEach((item) => item.state = false);

    this.enableCVTemplateFeatures();
  }

  private enableCVTemplateFeatures() {
    switch (this.template.documentType) {
      case DocumentTypes.CV: {
        const template = this.template.settings as TemplateConfigs;

        if (template.pages.items.length > 1) {
          this.enableFeature(PaidFeatures.MORE_THEN_ONE_PAGE);
        }

        if (template.premium) {
          this.enableFeature(PaidFeatures.PREMIUM_TEMPLATE);
        }

        if (template.img.settings.selectedShape.value !== 'initial') {
          this.enableFeature(PaidFeatures.PHOTO_STYLE);
        }

        const paidFonts = this.appStateService.templateFonts.filter((font) => font.paid)
          .map((font) => font.fontFamily);

        if (paidFonts.includes(template.settings.font.type.fontFamily)) {
          this.enableFeature(PaidFeatures.FONT);
        }

        if (!template.branding.enabled) {
          this.enableFeature(PaidFeatures.BRANDING);
        }


        const templateId = this.template.templateId || 1;
        const paidColorsIds = Object.values(this.appStateService.templateThemeColor[('template' + templateId) as keyof AllTemplatesThemeColor])
          .filter((color: TemplateSettingsThemeColor) => color.paid)
          .map((color: TemplateSettingsThemeColor) => color.id);

        if (paidColorsIds.includes(template.settings.theme.colorId)) {
          this.enableFeature(PaidFeatures.THEME_COLOR);
        }

        if (isPaidInfExist(this.template.settings as TemplateConfigs)) {
          this.enableFeature(PaidFeatures.INFOGRAPHICS);
        }

        break;
      }
      case DocumentTypes.COVER: {
        const template = this.template.settings as CoverLetter;

        this.enableFeature(PaidFeatures.COVER_LETTER);

        if (!template.branding.enabled) {
          this.enableFeature(PaidFeatures.BRANDING);
        }

        break;
      }
    }
  }

  private unsetInfographicsFeature(template: TemplateConfigs) {
    let listSections: TemplateSection[] = [];

    switch (template.type) {
      case TemplateTypes.SIDEBAR: {
        listSections = getSidebarAllPagesListSections(template)
        break
      }
      case TemplateTypes.ONE_ROW: {
        listSections = getOneRowAllPagesListSections(template)
        break;
      }
      case TemplateTypes.TWO_ROWS: {
        listSections = getTwoRowAllPagesListSections(template)
        break
      }
    }

    listSections
      ?.filter((section) => section.settings?.selectedShape.infographicValue) // infographic-round is the only free infographic
      ?.forEach((section) => section.settings!.selectedShape.infographicValue = 'infographic-round');
  }

  protected onCloseDrawer() {
    this.nzDrawerRef?.close();
  }

  private closeFeaturesDrawer() {
    const exist = this.selectedPaidFeatures.some((item) => item.state);
    if (!exist) {
      this.nzDrawerRef?.close();
    }
  }

  protected onChangeExistingTemplate(template: Template) {

    if (this.template.templateId === template.templateId) {
      return;
    }

    const selectedTemplate = cloneObject(template) as Template<TemplateConfigs>;
    const currentTemplate = this.template as Template<TemplateConfigs>;

    currentTemplate.templateId = selectedTemplate.templateId;
    currentTemplate.premium = selectedTemplate.premium;

    currentTemplate.settings.premium = selectedTemplate.settings.premium;
    currentTemplate.settings.templateTopPartType = selectedTemplate.settings.templateTopPartType;

    currentTemplate.settings.summary.icon = {state: false, name: ''};
    currentTemplate.settings.summary.title.state = false;

    currentTemplate.settings.socialInfo.title.state = selectedTemplate.settings.socialInfo.title.state;
    currentTemplate.settings.socialInfo.onSidebar = selectedTemplate.settings.socialInfo.onSidebar;

    currentTemplate.settings.settings.theme.colorId = selectedTemplate.settings.settings.theme.colorId;

    const allCurrentTmSections = [
      ...getOneRowAllPagesSections(currentTemplate.settings),
      ...getTwoRowAllPagesSections(currentTemplate.settings),
      ...getSidebarAllPagesSections(currentTemplate.settings),
      ...currentTemplate.settings.allSections.items
    ];

    allCurrentTmSections.forEach((section) => {
      section.title.icon = {state: false, name: ''};
    });

    this.disableFeature(PaidFeatures.PREMIUM_TEMPLATE);
    this.appStateService.templateThemeChanged$.next();

    this.disableFeature(PaidFeatures.THEME_COLOR);
  }

}
