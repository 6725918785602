<div class="templates-section">
  <section>
    <div class="top-part">
      <div class="title-top-desc">selfcv</div>
      <h2><strong>{{ ngTitle }}</strong> Templates</h2>
    </div>

    <div class="templates-list">
      @for (template of templates; track template.documentId) {
        <div class="template-item" (click)="onSelectTemplate(template)">
          <i class="sf-icon-zoom-btn" (click)="onPreviewTemplate($event, template)"></i>

          @if (template.premium) {
            <div class="prem">
              <i class="sf-icon-white-star"></i>
              <div class="mt-2">Premium</div>
            </div>
          } @else {
            <div class="prem prem-free">
              <i class="ph ph-hand-coins"></i>
              <div class="mt-2">Free</div>
            </div>
          }

          <img [ngSrc]="template.src" alt="selfcv" fill>

          @if (template.documentType === DocumentTypes.CV) {
            <sf-tm-buttons [template]="template"/>
          } @else {
            <button class="sf-btn-primary small cover-let-btn">
              <i class="ph ph-cursor-click"></i>
              Choose Template
            </button>
          }
        </div>
      }
    </div>
  </section>
</div>
